<template>
  <div>
    <div class="floating-loading" v-if="getDataLoading">
      <div
        class="d-flex"
        style="
          border: 1px solid #e8e9eb;
          width: 120px;
          padding: 10px;
          border-radius: 10px;
        "
      >
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 20px"
            class="mr-3 mb-0"
            spin
          />
        </a-spin>
        Loading...
      </div>
    </div>
    <div class="d-flex row justify-content-between align-items-center mb-2">
      <a-range-picker
        format="YYYY-MM-DD"
        style="width: 16%"
        @change="onDateChange"
      />

      <a-select
        v-model="statusQuery"
        style="width: 100%"
        class="col-xl-2"
        @change="onSearchChange"
      >
        <a-select-option value="">Semua</a-select-option>

        <a-select-option value="Selesai">Verifikasi Kode</a-select-option>
        <a-select-option value="Selesai Verifikasi Kode"
          >Selesai Verif Kode</a-select-option
        >
      </a-select>
      <a-select
        v-model="specializationQuery"
        style="width: 100%"
        class="col-xl-2"
        placeholder="Please select"
        :options="filteredSpecializations"
        show-search
        :filter-option="false"
        @search="handleSearch"
        @change="handleFilterSpecialization"
      />

      <a-input
        placeholder="Ketik untuk mencari nama pasien..."
        v-model="patientNameQuery"
        @change="onSearchChange"
        style="width: 100%"
        class="col-xl-6"
      >
        <template #prefix>
          <a-icon type="search" />
        </template>
      </a-input>
    </div>

    <div>
      <a-tabs>
        <a-tab-pane key="1" tab="Rawat Jalan">
          <div style="overflow-x: auto">
            <a-table
              :columns="columns"
              :dataSource="filteredDataRawatJalan"
              @rowClick="onRowRawatJalanClick"
              :rowKey="(record) => record.id"
            >
              <template slot="status" slot-scope="text">
                <div
                  :style="{
                    backgroundColor: getStatusBackgroundColor(text),
                    color: getStatusColor(text),
                    borderRadius: '999px',
                    textAlign: 'center',
                    width: '132px',
                    padding: '2px 8px 2px 8px',
                  }"
                >
                  {{
                    text === "Selesai"
                      ? "Verifikasi Kode"
                      : text === "Selesai Verifikasi Kode"
                      ? "Selesai Verif Kode"
                      : text
                  }}
                </div>
              </template>
            </a-table>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Rawat Inap" disabled>
          <!-- rawat inap -->
        </a-tab-pane>
        <a-tab-pane key="3" tab="IGD" disabled>
          <!-- IGD -->
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import { getVisitFilter } from "@/services/axios/api/kunjungan"
import { getAllSpecializations } from "@/services/axios/api/dokter"
import moment from "moment"

export default {
  data() {
    return {
      date: null,
      dateQuery: null,
      statusQuery: "Selesai",
      patientNameQuery: "",
      sortByQuery: "arrive_time.asc",
      includeDoctorDetail: true,
      includeAnamnesisDetail: true,
      doctor_id: "",
      filteredDataRawatJalan: [],
      getDataLoading: false,
      columns: [
        {
          title: "No.",
          key: "no",
          customRender: (text, record, index) => index + 1,
        },
        {
          title: "No. Kunjungan",
          dataIndex: "noKunjungan",
          key: "medicalRecordNo",
        },
        {
          title: "Nama Pasien",
          dataIndex: "Patient.name",
          key: "patientName",
        },
        {
          title: "Tanggal",
          dataIndex: "created_date",
          key: "createdDate",
        },
        {
          title: "Jam Datang",
          dataIndex: "created_time",
          key: "createdTime",
          sorter: (a, b) => {
            const timeA = a.created_time.split(":").map(Number)
            const timeB = b.created_time.split(":").map(Number)
            const secondsA = timeA[0] * 3600 + timeA[1] * 60 + timeA[2]
            const secondsB = timeB[0] * 3600 + timeB[1] * 60 + timeB[2]
            return secondsA - secondsB
          },
        },
        {
          title: "Kategori",
          dataIndex: "Patient.payment_method",
          key: "paymentMethod",
        },
        {
          title: "Tanggal Lahir",
          dataIndex: "birth_date",
          key: "birthDate",
        },
        {
          title: "Keluhan Utama",
          dataIndex: "keluhan_utama",
          key: "anamnesis",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
      ],
      ranges: {
        Today: [moment(), moment()],
        "This Month": [moment(), moment().endOf("month")],
      },
      filteredSpecializations: [],
      specializations: [],
      specializationQuery: "",
    }
  },
  created() {
    // const today = new Date().toISOString().split('T')[0]
    // this.dateQuery = today
    // console.log(this.dateQuery, "tanggal hari ini");
    // this.doctor_id = window.localStorage.getItem('id')
  },
  methods: {
    onChange(date, dateString) {
      this.dateQuery = dateString
      console.log(date, dateString)
    },
    onRowRawatJalanClick(record) {
      console.log(record, "record")
      // this.$router.push(`/pemeriksaan/dokter/periksa/${record.id}`);
      // if (record.status === 'Menunggu Perawat') {
      // } else if (
      //   record.status === 'Menunggu Dokter' ||
      //   record.status === 'Diperiksa Dokter'
      // ) {
      this.$router.push(`/pemeriksaan-koder/detail/${record.id}`)
      // } else {
      //   this.$router.push(`/pemeriksaan/detail/${record.id}`)
      // }
    },

    async getVisit() {
      this.getDataLoading = true
      const response = await getVisitFilter(
        this.dateQuery,
        this.statusQuery,
        this.patientNameQuery,
        this.sortByQuery,
        this.includeDoctorDetail,
        this.includeAnamnesisDetail,
        (this.doctor_id = ""),
        true,
        this.specializationQuery,
      )
      const { specializations: dataSpesializations } =
        await getAllSpecializations()
      this.specializations = dataSpesializations.map((e) => {
        return {
          value: e.id,
          label: e.name,
        }
      })
      this.filteredSpecializations = dataSpesializations.map((e) => {
        return {
          value: e.id,
          label: e.name,
        }
      })

      this.specializations.unshift({
        value: "",
        label: "Semua",
      })
      this.filteredSpecializations.unshift({
        value: "",
        label: "Cari Spesialisasi...",
      })
      // console.log(dataSpesializations, 'inispesialisasi')
      //   console.log(response.data.visits, 'data')
      this.filteredDataRawatJalan = response.data.visits.map((visit) => {
        let keluhan = ""
        if (visit.Anamnesis === null) {
          keluhan = "-"
        } else {
          for (const keluhanUtama in visit.Anamnesis.ChiefComplaints) {
            if (visit.Anamnesis.ChiefComplaints.length === 1) {
              keluhan +=
                visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
            } else {
              if (keluhanUtama > visit.Anamnesis.ChiefComplaints.length - 2) {
                keluhan +=
                  visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan
              } else {
                keluhan +=
                  visit.Anamnesis.ChiefComplaints[keluhanUtama].keterangan +
                  ", "
              }
            }
          }
        }
        // let noKunjungan = 'RJ'
        // console.log(visit, 'inipervisit')
        console.log(visit.arrive_time, "ini arrivetime")
        console.log(visit.Patient.medical_record_no, "ini medcord")
        console.log(
          visit.Doctor.Specialization.id,
          "ini doctor id spesialisasi",
        )
        // let noKunjungan = "RJ"

        // // Format tanggal visit (6 digit): DDMMYY
        // const visitDate = new Date(visit.arrive_time)
        // const formattedDate = visitDate
        //   .toISOString()
        //   .slice(0, 10) // Ambil format YYYY-MM-DD
        //   .split("-") // Pisahkan berdasarkan tanda -
        //   .reverse() // Balikkan urutannya ke [DD, MM, YY]
        //   .map((part, index) => (index === 2 ? part.slice(-2) : part)) // Ambil 2 digit terakhir tahun
        //   .join("") // Gabungkan tanpa separator

        // // Ambil 2 digit jam visit
        // const visitHour = visitDate.getUTCHours().toString().padStart(2, "0")

        // // Ambil 4 digit belakang nomor rekam medis pasien
        // const medicalRecordLast4 = visit.Patient.medical_record_no.slice(-3)

        // // ID spesialisasi dokter (2 digit)
        // const specializationId = visit.Doctor.Specialization.id
        //   .toString()
        //   .padStart(2, "0")

        // // Gabungkan format ke nomor kunjungan
        // noKunjungan += `${formattedDate}${visitHour}${medicalRecordLast4}${specializationId}`

        // console.log(noKunjungan)

        return {
          ...visit,
          birth_date: this.formatDate(visit.Patient.birth_date),
          created_date: this.formatDate(visit.createdAt),
          created_time: this.formatTime(visit.createdAt).replace(/\./g, ":"),
          Anamnesis: visit.Anamnesis ? visit.Anamnesis : "-",
          keluhan_utama: keluhan,
          // noKunjungan,
        }
      })
      console.log(this.filteredDataRawatJalan, "inifiltered")
      this.getDataLoading = false
    },
    handleSearch(value) {
      // Update filteredSpecializations based on the search term
      this.filteredSpecializations = this.specializations.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase()),
      )

      console.log(this.filteredSpecializations, "cekini")
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      const date = new Date(dateString)
      return date.toLocaleString("id-ID", options)
    },
    handleFilterSpecialization() {
      this.getVisit()
    },
    formatTime(dateString) {
      const options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      }
      const date = new Date(dateString)
      return date.toLocaleTimeString("id-ID", options)
    },
    onDateChange(dateRange) {
      if (!dateRange || dateRange.length === 0) {
        // Jika range kosong, reset filter
        this.dateQuery = null
        this.getVisit()
      } else {
        // Format startDate dan endDate
        const [startDate, endDate] = dateRange.map((date) =>
          moment(date).format("YYYY-MM-DD"),
        )
        console.log("Start:", startDate, "End:", endDate)
        this.dateQuery = { start: startDate, end: endDate } // Simpan sebagai object
        this.getVisit()
      }
    },
    onSearchChange() {
      this.getVisit()
    },
    getStatusColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#875BF7",
        "Menunggu Dokter": "#EF6820",
        "Diperiksa Dokter": "#2E90FA",
        Selesai: "#EF6820",
        "Selesai Verifikasi Kode": "#16B364",
      }
      return statusColorMap[status]
    },
    getStatusBackgroundColor(status) {
      const statusColorMap = {
        "Menunggu Perawat": "#F3F1FF",
        "Menunggu Dokter": "#FFF2E4",
        "Diperiksa Dokter": "#E1F1FF",
        Selesai: "#FFF2E4",
        "Selesai Verifikasi Kode": "#E1FBEA",
      }
      return statusColorMap[status]
    },
  },
  async mounted() {
    this.getVisit()
  },
}
</script>

<!-- <style lang="scss" module>
@import './style.module.scss';
</style> -->

<style scoped>
.floating-loading {
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  /* for mobile */
  @media (max-width: 768px) {
    top: 65px;
  }
}
</style>
